var site = site || {};
var generic = generic || {};
var hasIAM = Drupal.settings && Drupal.settings.iam_signin;
var iamData;

(function ($) {
  Drupal.behaviors.headerGnavAccountLinkV1 = {
    bindIamSignin: function ($statusClass) {
      if (hasIAM) {
        generic.jsonrpc.fetch({
          method: 'vulcan.getConfig',
          params: [{ section: 'iam' }],
          onBoth: function (jsonRpcResponse) {
            iamData = jsonRpcResponse.getValue();

            if (iamData && iamData.enabled && iamData.redirect_url && $statusClass.length > 0) {
              $statusClass.attr('href', iamData.redirect_url);
            }
          }
        });
      }
    },

    attach: function (context) {
      var self = this;
      var $template = $('.js-header-gnav-account-link-v1', context);
      var $statusClass = $template.find('.js-login-status--anonymous');
      var $accountLinkPc = $template.find('.header-gnav-account-link__top');
      var $anonElement = $template.find('.header-gnav-account-link--anon');
      var $authElement = $template.find('.header-gnav-account-link--auth');
      var $gnavFormatter = $template.closest('.gnav-header-formatter');
      var $anonName = $template.find('.account-auth-text--default');
      var $authName = $template.find('.account-auth-text--custom');
      var $authImg = $template.find('.header-gnav-account-link__avatar');
      var $anonLoyalty = $template.find('.js-header-gnav-loyalty-link--anon');
      var $authLoyalty = $template.find('.js-header-gnav-loyalty-link--auth');
      var authUser = generic.user.getUser();
      // Swap the default account link text for the username.
      function swapName(anon, auth) {
        if (!authUser) {
          return;
        }
        if (authUser.first_name && authUser.first_name.length > 0) {
          anon.addClass('hidden');
          auth.removeClass('hidden').find('span.first-name').html(authUser.first_name);
        }
        if (authUser.last_name && authUser.last_name.length > 0) {
          auth.find('span.last-name').html(authUser.last_name);
        }
      }

      // Show user avatar if available.
      function swapImg(img) {
        if (!authUser) {
          return;
        }
        if (authUser.avatar_small && authUser.avatar_small.length > 0) {
          img.removeClass('hidden').find('img').attr('src', authUser.avatar_small);
        }
      }

      // Loyalty Level display for logged-in users.
      function loyaltyLevel(anon, auth) {
        var levelName = site.userInfoCookie.getValue('loyalty_level_name');
        var loyaltyMember = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));
        if (!authUser) {
          return;
        }
        if (loyaltyMember === 1) {
          anon.addClass('hidden');
          auth.removeClass('hidden').find('a span').html(levelName);
        }
      }

      // If this is in the gnav formatter, move the offer display up
      if ($gnavFormatter.length && $accountLinkPc.length) {
        var $topLinkbar = $gnavFormatter.find('.gnav-header-formatter__top-account');

        $accountLinkPc.appendTo($topLinkbar).removeClass('hidden');
      }

      $(document).on('user.loaded', function () {
        var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));

        if (signedIn === 1) {
          // Toggle visibility for anon vs auth displays.
          $statusClass.removeClass('js-login-status--anonymous').addClass('js-login-status--logged-in');
          $anonElement.addClass('hidden');
          $authElement.removeClass('hidden');
          swapName($anonName, $authName);
          swapImg($authImg);
          loyaltyLevel($anonLoyalty, $authLoyalty);
        } else {
          self.bindIamSignin($statusClass);
        }
      });
    }
  };
})(jQuery);
